import { SimpleForm, Create } from "react-admin";

export default function CategoryCreate (props: any) {
    return (
        <Create {...props} redirect="list">
            <SimpleForm>
                
            </SimpleForm>
        </Create>
    )
}