import { TreeItem, TreeView } from "@mui/lab";
import { useGetOne } from "react-admin";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useState, useEffect } from "react";
import CategoryEdit from "./CategoryEdit";
import { useUpdate } from "react-admin";
import { Button, Box } from "@mui/material";
import { customAlphabet } from 'nanoid';
import { app } from "../../firebase";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const nanoid = customAlphabet("abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789");

export default function CategoryList (props: any) {

    const { data } = useGetOne(
        "categories",
        { id: "categories"}
    )

    const [categories, setCategories] = useState(data);
    const [selectedCategory, setSelectedCategory] = useState<{id: string, children: Array<string>}>({id: "", children: []});
    const [update, { isLoading }] = useUpdate();
    const [expanded, setExpanded] = useState<string[]>([]);
    const [selected, setSelected] = useState<string>("");
    const [unsavedCategoryId, setUnsavedCategoryId] = useState<string>("");
    const [language, setLanguage] = useState("sk");

    useEffect(() => {
        if (!categories){
            setCategories(structuredClone(data));
        }
    }, [categories, data])

    const onCategorySelect = (event: React.SyntheticEvent, nodeId: string) => {
        setSelectedCategory({...categories?.[nodeId], id: nodeId});
        setSelected(nodeId);

        if (unsavedCategoryId !== undefined && unsavedCategoryId !== "" && nodeId !== unsavedCategoryId){
            //console.log(unsavedCategoryId !== undefined && unsavedCategoryId !== "" && nodeId !== unsavedCategoryId);
            setCategories((prevState: any) => {
                const newState = structuredClone(prevState);
                //console.log(newState[unsavedCategoryId]);
                const parentId = newState[unsavedCategoryId]?.parent;
                newState[parentId]?.children?.splice(newState[parentId]?.children?.indexOf(unsavedCategoryId), 1);
                delete newState[unsavedCategoryId];
                return newState;
            })
            setUnsavedCategoryId("");
        }
        //console.log(categories?.[nodeId]?.description);
    }
    //console.log(selectedCategory);
    const onCategoryCreate = (selectedCategory: {id: string, children: Array<string>}) => {
        const newId = nanoid();
        //console.log(categories);
        if (selectedCategory.id){
            const {id, ...parentCategory} = {...selectedCategory};
            parentCategory.children.push(newId);
            const newCategory = {
                parent: id,
                children: [],
                title: {sk: "--nova kategoria--", en: "--nova kategoria--"},
                slug: {sk: "", en: ""},
                description: {sk: "", en: ""},
            }
            setCategories((prevState: any) => ({
                ...structuredClone(prevState),
                [id]: parentCategory,
                [newId]: newCategory,
            }));
            setExpanded((prevState) => {
                prevState.push(id);
                return prevState;
            });
            setSelected(newId);
            setSelectedCategory({...newCategory, id: newId});
            //console.log(newId);
            setUnsavedCategoryId(newId);
        }
    }

    const onCategoryUpdate = ({id, ...updatedCategory}: {id: string, children: Array<string>, title: any}) => {
        // console.log(id);
        let sameExists = [false, ""];
        for (const [cId, category] of Object.entries<any>(categories)){
            //console.log(category);
            if (category?.title && updatedCategory?.title && cId !== id){
                if (updatedCategory.title?.sk === category.title?.sk){
                        sameExists = [true, "SK"];
                    }
                if (updatedCategory.title?.en === category.title?.en){
                        sameExists = [true, "EN"];
                }
            }
        }
        if (sameExists[0]) {
            window.alert("Kategória s týmto názvom už existuje. [language:" + sameExists[1] + "]");
        }
        else {
            const newCategories = {...categories, [id]: updatedCategory};
            update("categories", {id: "categories", data: newCategories, previousData: data});
            setCategories((prevState: any) => ({
                ...prevState,
                [id]: updatedCategory,
            }));
            setSelectedCategory({...updatedCategory, id: id, children: updatedCategory.children});
            setUnsavedCategoryId("");
        }
    }

    const onCategoryDelete = async (id: string) => {
        //console.log(id);
        if (id !== "root"){
            const newCategories = {...categories};
            const parent = newCategories?.[newCategories[id]?.parent];

            parent?.children?.splice(parent?.children?.indexOf(id), 1);
            const idsToDelete: any[] = [];
            const getChildrenIds = (parentId: string) => {
                idsToDelete.push(parentId);
                categories[parentId]?.children?.forEach((childId: string) => {
                    getChildrenIds(childId);
                });
            }
            getChildrenIds(id);
            idsToDelete.forEach((idToDelete: string) => {
                delete newCategories[idToDelete];
            });
            //console.log(newCategories);
 
            const db = app.firestore();
            await db.collection("categories").doc("categories").set(newCategories);
            // update("categories", {id: "categories", data: newCategories});
            setCategories(newCategories);
            setExpanded((prevState: any) => {
                delete prevState[id];
                return prevState;
            });
            setSelected("");
            setSelectedCategory({id: "", children: []});
            setUnsavedCategoryId("");
        }
        else {
            window.alert("Kec toto vymažeš, ta už nebudzeš mac kategorie nikda. Taže zabudni na to.");
        }
    }

    const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
        setExpanded(nodeIds);
      };

    const handleLanguageChange = (event: React.SyntheticEvent, newValue: string) => {
        setLanguage(newValue);
    };

    const renderCategory = (id: string) => {
        const category = categories?.[id];
        //console.log(category);
        if (category){
            return (
                <TreeItem label={category?.title[language]} nodeId={id} key={id}>
                    {
                        category?.children?.map((childCategory: string) => {
                            return renderCategory(childCategory);
                        })
                    }
                </TreeItem>
            )
        }
        else return (null)
    }

    return (
        <Box sx={{ m: 2, p: 2}} >
            <Tabs value={language} onChange={handleLanguageChange} sx={{mb: 2}}>
                <Tab value="sk" label="SK" />
                <Tab value="en" label="EN" />
            </Tabs>
            <TreeView
                aria-label="file system navigator"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                onNodeSelect={onCategorySelect}
                expanded={expanded}
                selected={selected}
                onNodeToggle={handleToggle}
                sx={{ mb: 2}}
                >
                {renderCategory("root")}
            </TreeView>
            <Button onClick={() => {onCategoryCreate(selectedCategory)}} disabled={!!unsavedCategoryId || selectedCategory.id === ""} variant="contained" sx={{mx: 2}}>{"Pridať kategóriu"}</Button>
            <span>(najprv označ 'rodičovskú' kategóriu)</span>
            <CategoryEdit
                selectedCategory={selectedCategory}
                onCategoryUpdate={onCategoryUpdate}
                onCategoryDelete={onCategoryDelete}
                isLoading={isLoading}
                unsavedChanges={!!unsavedCategoryId}
                language={language}
                />
        </Box>
    )
}
