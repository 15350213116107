import { Show, SimpleShowLayout, TextField } from "react-admin";

export default function ProductShow (props: any) {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="id" />
                <TextField source="title" />
                {/* <FileField source="file.src" title="file.title" /> */}
            </SimpleShowLayout>
        </Show>
    )
}
