import firebase from "firebase/compat/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getStorage, connectStorageEmulator } from "firebase/storage";

export const config = require("./FIREBASE_CONFIG.js").firebaseConfig;

const app = firebase.initializeApp(config);
export const auth = getAuth();
export const firestore = getFirestore();
export const functions = getFunctions();
export const storage = getStorage();

// if (window.location.hostname === "localhost") {
//   connectAuthEmulator(auth, "http://localhost:9099");
//   connectFirestoreEmulator(firestore, "localhost", 8080);
//   connectFunctionsEmulator(functions, "localhost", 5001);
//   connectStorageEmulator(storage, "localhost", 9199);
//   // firebase.firestore.setLogLevel("debug");
// }

export { app };
