import { Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { TextField, Button, Box } from "@mui/material";
import slugify from "slugify";

export default function CategoryEdit({
  selectedCategory = null,
  onCategoryUpdate,
  onCategoryDelete,
  isLoading,
  unsavedChanges,
  language,
  ...rest
}: {
  selectedCategory: any;
  onCategoryUpdate: any;
  onCategoryDelete: any;
  isLoading: boolean;
  unsavedChanges: boolean;
  language: string;
}) {
  const [category, setCategory] = useState(selectedCategory);

  useEffect(() => {
    setCategory(selectedCategory);
  }, [selectedCategory]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCategory((prevState: any) => {
      const newState = structuredClone(prevState);
      if (event.target.id === "order") {
        newState[event.target.id] = event.target.value;
      } else {
        newState[event.target.id][language] = event.target.value;
      }
      if (event.target.id === "title") {
        newState["slug"][language] = slugify(event.target.value, {
          locale: language,
          lower: true,
        });
      }
      return newState;
    });
  };

  const handleSubmit = () => {
    onCategoryUpdate(category);
  };

  const handleDelete = () => {
    const deleteConfirmed = window.confirm(
      "Naozaj chcete vymazať túto kategóriu a všetky jej podkategórie?"
    );
    if (deleteConfirmed) {
      onCategoryDelete(category?.id);
    }
    //onCategoryUpdate(category);
  };

  // console.log(selectedCategory);
  // console.log(category);
  if (category.id) {
    return (
      <Paper sx={{ mt: 2, p: 2 }}>
        <TextField
          id="title"
          value={category?.title?.[language]}
          onChange={handleChange}
          label="Názov"
          disabled={isLoading}
          variant="outlined"
          fullWidth
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          id="slug"
          value={category?.slug?.[language]}
          onChange={handleChange}
          label="Slug"
          disabled
          variant="outlined"
          fullWidth
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          id="description"
          value={category?.description?.[language]}
          onChange={handleChange}
          label="Popis"
          disabled={isLoading}
          variant="outlined"
          fullWidth
          multiline
          minRows={3}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          id="order"
          type="number"
          value={category?.order || ""}
          onChange={handleChange}
          label="Poradie"
          disabled={isLoading}
          variant="outlined"
          fullWidth
          InputLabelProps={{ shrink: true }}
        />
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            onClick={handleSubmit}
            disabled={isLoading || category?.title === ""}
            variant="contained"
            sx={{ mt: 2 }}
            color="success"
          >
            Save
          </Button>
          <Button
            onClick={handleDelete}
            disabled={isLoading || category?.id === "root"}
            variant="contained"
            sx={{ mt: 2 }}
            color="error"
          >
            Delete
          </Button>
        </Box>
      </Paper>
    );
  } else return null;
}
