import { ImageField } from "react-admin";
import { SelectField } from "react-admin";
import { SelectInput } from "react-admin";
import { Datagrid, List, TextField, EditButton } from "react-admin";
import ListPagination from "../../components/ListPagination";

// function CategoryInput(props: any) {
//   const { data } = useGetOne("categories", { id: "categories" });
//   const choices: any[] = [];
//   const createCategoryList = (parentId: string, previousName: string) => {
//     if (data && data[parentId]) {
//       const name =
//         (previousName ? previousName + "->" : "") + data[parentId]?.title.sk;
//       if (data[parentId]?.children?.length > 0) {
//         data[parentId]?.children.forEach((childId: string) => {
//           createCategoryList(childId, name);
//         });
//       } else {
//         const item = { id: parentId, name: name };
//         choices.push(item);
//       }
//     }
//   };

//   createCategoryList("root", "");

//   return (
//     <SelectInput
//       label="Kategória"
//       source=""
//       choices={choices}
//       defaultValue={null}
//     />
//   );
// }

// const productFilter = [<CategoryInput key="categoryFilter" />];

const productFilters = [
        <SelectInput
                label="Stav"
                source="state"
                alwaysOn
                choices={[
                        { id: "public", name: "Zverejnený" },
                        { id: "draft", name: "Koncept" },
                        { id: "archived", name: "Archivovaný" },
                ]}
        />,
];

export default function ProductList(props: any) {
        return (
                <List
                        {...props}
                        filters={productFilters}
                        pagination={<ListPagination />}
                        sort={{ field: "createdAt", order: "DESC" }}
                >
                        <Datagrid bulkActionButtons={false} rowClick="edit">
                                <ImageField source="mainImage.src" label="" />
                                <TextField source="title.sk" label="Názov" />
                                <SelectField
                                        sortable={false}
                                        source="state"
                                        label="Stav"
                                        choices={[
                                                { id: "public", name: "Zverejnený" },
                                                { id: "draft", name: "Koncept" },
                                                { id: "archived", name: "Archivovaný" },
                                        ]}
                                />

                                <TextField source="price" label="Cena" sortable={false} />
                                <TextField source="count" label="Počet" sortable={false} />
                                {/* <ShowButton label="" /> */}
                                <EditButton label="" />
                        </Datagrid>
                </List>
        );
}
