import { NumberInput } from "react-admin";
import { SelectField } from "react-admin";
import { DateField } from "react-admin";
import { Datagrid, List, TextField, EditButton } from "react-admin";
import ListPagination from "../../components/ListPagination";
import { FunctionField } from "react-admin";
import { Button, Box, Select, MenuItem } from "@mui/material";
import { useRefresh } from "react-admin";
import { useState } from "react";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../firebase";

const orderFilters = [
        <NumberInput label="Číslo objednávky" source="orderId" alwaysOn />,
        // <SelectInput
        //   label="Stav"
        //   source="state"
        //   alwaysOn
        //   choices={[
        //     { id: "RECEIVED", name: "Prijatá" },
        //     { id: "CONFIRMED", name: "Potvrdená" },
        //     { id: "CANCELED", name: "Vymazané" },
        //     { id: "RENTED", name: "Požičané" },
        //     { id: "RETURNED", name: "Vrátené" },
        //     { id: "CLOSED", name: "Uzavreté" },
        //   ]}
        // />,
];

export default function OrderList(props: any) {
        // const [update] = useUpdate();
        //   const test = httpsCallable(functions, "test");
        const updateOrder = httpsCallable(functions, "updateOrder");
        const refresh = useRefresh();
        const [state, setState] = useState<string>(
                JSON.stringify(["RECEIVED", "CONFIRMED", "RENTED", "RETURNED", "CLOSED"])
        );

        return (
                <Box>
                        <Select
                                value={state}
                                variant="standard"
                                onChange={(e) => {
                                        setState(e.target.value);
                                }}
                                label="Stav"
                                sx={{ mt: 2, minWidth: 120 }}
                        >
                                <MenuItem
                                        value={JSON.stringify([
                                                "RECEIVED",
                                                "CONFIRMED",
                                                "RENTED",
                                                "RETURNED",
                                                "CLOSED",
                                        ])}
                                >
                                        Všetky
                                </MenuItem>
                                <MenuItem value={JSON.stringify("RECEIVED")}>Prijatá</MenuItem>
                                <MenuItem value={JSON.stringify("CONFIRMED")}>Potvrdená</MenuItem>
                                <MenuItem value={JSON.stringify("RENTED")}>Požičané</MenuItem>
                                <MenuItem value={JSON.stringify("RETURNED")}>Vrátené</MenuItem>
                                <MenuItem value={JSON.stringify("CLOSED")}>Uzavreté</MenuItem>
                                <MenuItem value={JSON.stringify("CANCELED")}>Vymazané</MenuItem>
                        </Select>

                        <List
                                {...props}
                                filters={orderFilters}
                                filter={{
                                        state: JSON.parse(state),
                                }}
                                sort={{ field: "createdAt", order: "DESC" }}
                                pagination={<ListPagination />}
                        >
                                <Datagrid bulkActionButtons={false} rowClick="edit">
                                        <TextField source="orderId" label="Číslo objednávky" sortable={false} />
                                        <TextField source="user.lastName" label="Priezvisko" sortable={false} />
                                        <TextField source="user.email" label="Email" sortable={false} />
                                        <SelectField
                                                sortable={false}
                                                source="state"
                                                label="Stav"
                                                choices={[
                                                        { id: "RECEIVED", name: "Prijatá" },
                                                        { id: "CONFIRMED", name: "Potvrdená" },
                                                        { id: "CANCELED", name: "Vymazaná" },
                                                        { id: "RENTED", name: "Požičané" },
                                                        { id: "RETURNED", name: "Vrátené" },
                                                        { id: "CLOSED", name: "Uzavreté" },
                                                ]}
                                        />
                                        <DateField source="rentAt" label="Vyzdvihnutie" locales="sk" sortable={false} />
                                        <DateField source="returnAt" label="Vrátenie" locales="sk" sortable={false} />
                                        <TextField source="price" label="Cena" sortable={false} />
                                        {/* <ShowButton label="" /> */}
                                        <EditButton label="" />
                                        <FunctionField
                                                render={(record: any) => {
                                                        return (
                                                                <Button
                                                                        onClick={(event) => {
                                                                                if (
                                                                                        window.confirm(
                                                                                                "Si si istý svojími životnými rozhodnutiami?"
                                                                                        )
                                                                                ) {
                                                                                        console.log(record);
                                                                                        const editedOrder: any = {
                                                                                                rentAt: record.rentAt,
                                                                                                returnAt: record.returnAt,
                                                                                                products: record.products,
                                                                                                price: record.price,
                                                                                                state: "CANCELED",
                                                                                                managerNote:
                                                                                                        record.managerNote,
                                                                                        };
                                                                                        updateOrder({
                                                                                                id: record.id,
                                                                                                oldOrder: record,
                                                                                                clientUpdate:
                                                                                                        editedOrder,
                                                                                        }).then(() => refresh());
                                                                                }
                                                                                event.stopPropagation();
                                                                        }}
                                                                >
                                                                        {"Vymazať"}
                                                                </Button>
                                                        );
                                                }}
                                        />
                                </Datagrid>
                        </List>
                </Box>
        );
}
