import { Edit } from "react-admin";
import { useRecordContext } from "react-admin";
import {
  TextField,
  Box,
  Typography,
  MenuItem,
  IconButton,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { firestore, functions } from "../../firebase";
import { httpsCallable } from "firebase/functions";
import { useNotify } from "react-admin";
import { collection, getDocs } from "firebase/firestore";
import { useRefresh } from "react-admin";
import { OUTSIDE_WORK_HOURS_PRICE } from "../../utils/constants";

const stateOptions = [
  { value: "RECEIVED", label: "Prijatá" },
  { value: "CONFIRMED", label: "Potvrdená" },
  { value: "CANCELED", label: "Vymazaná" },
  { value: "RENTED", label: "Požičané" },
  { value: "RETURNED", label: "Vrátené (čakajúca platba)" },
  { value: "CLOSED", label: "Uzavreté" },
];

function alignProductAvailability(
  products: any,
  orderedProducts: any,
  startDate: DateTime,
  endDate: DateTime
) {
  products = structuredClone(products);
  orderedProducts.forEach((orderedProduct: any) => {
    let idxDate = startDate;
    let safeguard = 0;
    const productId = products.findIndex(
      (product: any) => product.id === orderedProduct.id
    );
    do {
      products[productId].rentedAt[`${idxDate.year}`][`${idxDate.month}`][
        `${idxDate.day}`
      ] -= orderedProduct.count;
      safeguard++;
      idxDate = idxDate.plus({ days: 1 });
    } while (
      idxDate.startOf("day") <= endDate.startOf("day") &&
      safeguard < 10000
    );
    if (safeguard >= 10000) {
      console.log("coska nedobre.");
    }
  });
  return products;
}

function filterProductAvailability(
  products: any,
  startDate: DateTime,
  endDate: DateTime
) {
  products = structuredClone(products);
  products.forEach((product: any) => {
    let maxRented = 0;
    let idxDate = startDate;
    let safeguard = 0;
    do {
      const rentedCount =
        product?.rentedAt?.[`${idxDate.year}`]?.[`${idxDate.month}`]?.[
          `${idxDate.day}`
        ] || 0;
      maxRented = Math.max(maxRented, rentedCount);
      safeguard++;
      idxDate = idxDate.plus({ days: 1 });
    } while (
      idxDate.startOf("day") <= endDate.startOf("day") &&
      safeguard < 10000
    );
    if (safeguard >= 10000) {
      console.log("coska nedobre.");
    }
    product.availableCount = Math.max(product.count - maxRented, 0);
  });

  return products;
}

function sortProducts(products: any) {
  products.sort((a: any, b: any) => {
    const titleA = a?.title?.sk?.toUpperCase();
    const titleB = b?.title?.sk?.toUpperCase();
    if (titleA < titleB) {
      return -1;
    }
    if (titleA > titleB) {
      return 1;
    }
    return 0;
  });
  return products;
}

function calculateIsOutsideWorkHours(startDate: DateTime, endDate: DateTime) {
  return (
    startDate.hour < 8 ||
    startDate.hour > 15 ||
    endDate.hour < 8 ||
    endDate.hour > 15 ||
    startDate.weekday === 6 ||
    startDate.weekday === 7 ||
    endDate.weekday === 6 ||
    endDate.weekday === 7
  );
}

function calculatePrice(products: any, startDate: DateTime, endDate: DateTime) {
  const isOutsideWorkHours = calculateIsOutsideWorkHours(startDate, endDate);
  let idxDate = startDate;
  const rentDays: DateTime[] = [];
  let safeguard = 0;
  let totalPrice = 0;
  do {
    rentDays.push(idxDate);
    safeguard++;
    idxDate = idxDate.plus({ days: 1 });
  } while (
    idxDate.startOf("day") <= endDate.startOf("day") &&
    safeguard < 1000
  );
  if (safeguard >= 10000) {
    console.log("coska nedobre.");
  }
  products.forEach((product: any) => {
    totalPrice +=
      (product.salePrice ? product.salePrice : product.price) *
      (parseInt(product.desiredCount, 10) || 0) *
      rentDays.length;
  });
  totalPrice += isOutsideWorkHours ? OUTSIDE_WORK_HOURS_PRICE : 0;
  return totalPrice;
}

const OrderTitle = () => {
  const record = useRecordContext();
  return (
    <span>
      Objednávka: <b>{record ? `${record.orderId}` : ""}</b>
    </span>
  );
};

function OrderEditFields() {
  const record = useRecordContext();
  const refresh = useRefresh();
  const notify = useNotify();

  const [startDate, setStartDate] = useState<DateTime>(
    DateTime.fromJSDate(record.rentAt)
  );
  const [endDate, setEndDate] = useState<DateTime>(
    DateTime.fromJSDate(record.returnAt)
  );
  const [price, setPrice] = useState<string>(record.price.toFixed(2));
  const [products, setProducts] = useState<any>({ options: [], selected: [] });
  const [productsData, setProductsData] = useState<any>([]);
  const [orderState, setOrderState] = useState<string>(record.state);
  const [managerNote, setManagerNote] = useState<string>(record.managerNote);
  const [editingEnabled, setEditingEnabled] = useState<boolean>(false);
  const [awaitingResponse, setAwaitingResponse] = useState<boolean>(false);

  const isOutsideWorkHours = calculateIsOutsideWorkHours(startDate, endDate);

  useEffect(() => {
    if (editingEnabled && productsData.length === 0) {
      getDocs(collection(firestore, "products")).then((snapshot) => {
        let newProductsData: any[] = [];
        snapshot.forEach((doc) => {
          newProductsData.push(doc.data());
        });
        const startDate = DateTime.fromJSDate(record.rentAt);
        const endDate = DateTime.fromJSDate(record.returnAt);
        newProductsData = alignProductAvailability(
          newProductsData,
          record.products,
          startDate,
          endDate
        );
        newProductsData = filterProductAvailability(
          newProductsData,
          startDate,
          endDate
        );
        const newProducts: { options: any[]; selected: any[] } = {
          options: [],
          selected: [],
        };
        newProductsData.forEach((product: any) => {
          const orderedProduct = record.products.find(
            (orderedProduct: any) => orderedProduct.id === product.id
          );
          if (orderedProduct) {
            product.desiredCount = "" + orderedProduct.count;
            newProducts.selected.push(product);
          } else {
            newProducts.options.push(product);
          }
        });
        setProductsData(newProductsData);
        sortProducts(newProducts.options);
        sortProducts(newProducts.selected);
        setProducts(newProducts);
      });
    }
  }, [
    editingEnabled,
    productsData.length,
    record.products,
    record.rentAt,
    record.returnAt,
  ]);

  const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrice(event.target.value.replace(",", "."));
  };

  const handleStartDateChange = (newValue: DateTime | null) => {
    if (newValue === null) {
      setStartDate(DateTime.now());
    } else if (newValue >= endDate) {
      setEndDate(newValue.plus({ minutes: 1 }));
      setStartDate(newValue);
      if (editingEnabled && productsData.length !== 0) {
        setPrice(
          "" + calculatePrice(products.selected, newValue, newValue).toFixed(2)
        );
        setProducts((oldProducts: any) => {
          const newProducts: any = {};
          newProducts.options = filterProductAvailability(
            oldProducts.options,
            newValue,
            newValue
          );
          newProducts.selected = filterProductAvailability(
            oldProducts.selected,
            newValue,
            newValue
          );
          return newProducts;
        });
      }
    } else {
      setStartDate(newValue);
      if (editingEnabled && productsData.length !== 0) {
        setPrice(
          "" + calculatePrice(products.selected, newValue, endDate).toFixed(2)
        );
        setProducts((oldProducts: any) => {
          const newProducts: any = {};
          newProducts.options = filterProductAvailability(
            oldProducts.options,
            newValue,
            endDate
          );
          newProducts.selected = filterProductAvailability(
            oldProducts.selected,
            newValue,
            endDate
          );
          return newProducts;
        });
      }
    }
  };

  const handleEndDateChange = (newValue: DateTime | null) => {
    if (newValue === null) {
      setEndDate(DateTime.now());
    } else if (newValue < startDate) {
      // setEndDate(startDate);
      // if (editingEnabled && productsData.length !== 0){
      //     setPrice("" + calculatePrice(products.selected, startDate, startDate).toFixed(2));
      //     setProducts((oldProducts: any) => {
      //         const newProducts: any = {};
      //         newProducts.options = filterProductAvailability(oldProducts.options, startDate, startDate);
      //         newProducts.selected = filterProductAvailability(oldProducts.selected, startDate, startDate);
      //         return newProducts;
      //     })
      // }
    } else {
      setEndDate(newValue);
      if (editingEnabled && productsData.length !== 0) {
        setPrice(
          "" + calculatePrice(products.selected, startDate, newValue).toFixed(2)
        );
        setProducts((oldProducts: any) => {
          const newProducts: any = {};
          newProducts.options = filterProductAvailability(
            oldProducts.options,
            startDate,
            newValue
          );
          newProducts.selected = filterProductAvailability(
            oldProducts.selected,
            startDate,
            newValue
          );
          return newProducts;
        });
      }
    }
  };

  const handleProductSelect = (event: any) => {
    const selectedValue = structuredClone(event.target.value);
    selectedValue.desiredCount = "1";
    setProducts((oldProducts: any) => {
      const newProducts = structuredClone(oldProducts);
      newProducts.options = newProducts.options.filter(
        (product: any) => product.id !== selectedValue.id
      );
      newProducts.selected.push(selectedValue);
      sortProducts(newProducts.options);
      sortProducts(newProducts.selected);
      setPrice(
        "" + calculatePrice(newProducts.selected, startDate, endDate).toFixed(2)
      );
      return newProducts;
    });
  };

  const handleProductDeselect = (product: any) => {
    const deselectedProduct = structuredClone(product);
    deselectedProduct.desiredCount = 0;
    setProducts((oldProducts: any) => {
      const newProducts = structuredClone(oldProducts);
      newProducts.selected = newProducts.selected.filter(
        (product: any) => product.id !== deselectedProduct.id
      );
      newProducts.options.push(deselectedProduct);
      sortProducts(newProducts.options);
      sortProducts(newProducts.selected);
      setPrice(
        "" + calculatePrice(newProducts.selected, startDate, endDate).toFixed(2)
      );
      return newProducts;
    });
  };

  const handleCountChange = (value: string, product: any) => {
    let count: string | number = parseInt(value, 10);
    if (count > product.availableCount) {
      count = product.availableCount;
    } else if (count < 1) {
      count = 1;
    } else {
      count = value;
    }
    setProducts((oldProducts: any) => {
      const newProducts = structuredClone(oldProducts);
      const newProduct = newProducts.selected.find(
        (prod: any) => prod.id === product.id
      );
      newProduct.desiredCount = "" + count;
      setPrice(
        "" + calculatePrice(newProducts.selected, startDate, endDate).toFixed(2)
      );
      return newProducts;
    });
  };

  const handleOrderStateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOrderState(event.target.value);
  };

  const handleManagerNoteChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setManagerNote(event.target.value);
  };

  const handleSaveButton = () => {
    setAwaitingResponse(true);
    console.log("Editing order...");

    const findDesiredCountError = products.selected.some(
      (product: any) => product.desiredCount > product.availableCount
    );
    if (findDesiredCountError) {
      setAwaitingResponse(false);
      notify(`Niektorý z produktov nie je dostupný v požadovanom množstve`, {
        type: "warning",
      });
      return;
    }

    const transformedProducts = products.selected.map((product: any) => {
      return {
        count: parseInt(product?.desiredCount),
        id: product?.id,
        title: product?.title?.sk,
        price: product.salePrice ? product.salePrice : product.price,
      };
    });

    const editedOrder: any = {
      rentAt: startDate.toISO(),
      returnAt: endDate.toISO(),
      products: editingEnabled ? transformedProducts : record.products,
      price: parseFloat(price),
      state: orderState,
      managerNote: managerNote,
    };

    const updateOrder = httpsCallable(functions, "updateOrder");
    updateOrder({ id: record.id, oldOrder: record, clientUpdate: editedOrder })
      .then((result: any) => {
        //const data = result.data;
        //console.log(data);
        setAwaitingResponse(false);
        setEditingEnabled(false);
        setProductsData([]);
        notify(result?.data?.message, {
          type: result?.data?.success ? "success" : "error",
        });
        refresh();
      })
      .catch((e) => {
        notify("Dajaka chyba, neznam.", { type: "error" });
        setAwaitingResponse(false);
        setEditingEnabled(false);
        setProductsData([]);
        refresh();
      });
    //console.log(editedOrder);
  };

  return (
    <Box sx={{ m: 2 }}>
      {record?.completedAt && (
        <Typography>
          {" "}
          Objednávka uzavretá{" "}
          <b>{record?.completedAt?.toLocaleString("sk-SK")}</b>{" "}
        </Typography>
      )}
      <Typography>
        {" "}
        ID objednávky: <b>{record?.orderId}</b>{" "}
      </Typography>
      <Typography>
        {" "}
        ID v databáze: <b>{record?.id}</b>{" "}
      </Typography>
      <Typography>
        {" "}
        Vytvorené: <b>{record?.createdAt?.toLocaleString("sk-SK")}</b>{" "}
      </Typography>
      <Typography>
        {" "}
        Upravené <b>{record?.updatedAt?.toLocaleString("sk-SK")}</b>
      </Typography>
      <Typography>
        {" "}
        Poznámka zákazníka: <b>{record?.customerNote}</b>{" "}
      </Typography>

      <Accordion sx={{ mt: 2, backgroundColor: "#3a3a3aba" }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            {" "}
            <b>{`${record?.user?.firstName} ${record?.user?.lastName}`}</b>{" "}
          </Typography>
          {record?.user?.company && (
            <Typography sx={{ ml: 2 }}>
              {" "}
              <b>{`(${record?.user?.company})`}</b>{" "}
            </Typography>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ ml: 2 }}>
            <Typography>
              {" "}
              Na firmu?: <b>{record?.user?.onCompany ? "Áno" : "Nie"}</b>{" "}
            </Typography>
            <Typography>
              {" "}
              Meno:{" "}
              <b>{`${record?.user?.firstName} ${record?.user?.lastName}`}</b>{" "}
            </Typography>
            <Typography>
              {" "}
              Email: <b>{record?.user?.email}</b>{" "}
            </Typography>
            <Typography>
              {" "}
              Phone: <b>{record?.user?.phone}</b>{" "}
            </Typography>
          </Box>
          <Typography sx={{ mt: 1 }}> Adresa: </Typography>
          <Box sx={{ ml: 2 }}>
            <Typography>
              {" "}
              Krajina: <b>{record?.user?.country}</b>{" "}
            </Typography>
            <Typography>
              {" "}
              Mesto: <b>{record?.user?.city}</b>{" "}
            </Typography>
            <Typography>
              {" "}
              PSČ: <b>{record?.user?.zip}</b>{" "}
            </Typography>
            <Typography>
              {" "}
              Ulica: <b>{record?.user?.street}</b>{" "}
            </Typography>
          </Box>
          {record?.user?.onCompany && (
            <>
              <Typography sx={{ mt: 1 }}> Firma: </Typography>
              <Box sx={{ ml: 2 }}>
                <Typography>
                  {" "}
                  Názov: <b>{record?.user?.company}</b>{" "}
                </Typography>
                <Typography>
                  {" "}
                  IČO: <b>{record?.user?.companyId}</b>{" "}
                </Typography>
                <Typography>
                  {" "}
                  iČ DPH: <b>{record?.user?.vatId}</b>{" "}
                </Typography>
                <Typography>
                  {" "}
                  DIČ: <b>{record?.user?.taxId}</b>{" "}
                </Typography>
              </Box>
            </>
          )}
        </AccordionDetails>
      </Accordion>
      <Box sx={{ mt: 2 }}>
        <DateTimePicker
          label="Začiatok"
          onChange={handleStartDateChange}
          value={startDate}
          renderInput={(params: any) => (
            <TextField
              onKeyDown={(e) => {
                e.preventDefault();
              }}
              {...params}
            />
          )}
          inputFormat="dd/MM/yyyy HH:mm"
          minDate={editingEnabled ? undefined : startDate}
          maxDate={editingEnabled ? undefined : startDate}
          ampm={false}
          openTo={editingEnabled ? "day" : "hours"}
          disabled={record.state === "CANCELED"}
        />
        <DateTimePicker
          label="Koniec"
          onChange={handleEndDateChange}
          value={endDate}
          renderInput={(params: any) => (
            <TextField
              onKeyDown={(e) => {
                e.preventDefault();
              }}
              {...params}
            />
          )}
          inputFormat="dd/MM/yyyy HH:mm"
          minDate={editingEnabled ? startDate : endDate}
          maxDate={editingEnabled ? undefined : endDate}
          minDateTime={startDate.plus({ minutes: 1 })}
          ampm={false}
          openTo={editingEnabled ? "day" : "hours"}
          disabled={record.state === "CANCELED"}
        />
        <Button
          disabled={editingEnabled || record.state === "CANCELED"}
          onClick={() => {
            setEditingEnabled(true);
          }}
          variant="contained"
          sx={{ mt: 2, ml: 4 }}
        >
          Zmeniť dátum/produkty
        </Button>
        <Typography>
          {" "}
          Mimo pracovný čas?: <b>{isOutsideWorkHours ? "Áno" : "Nie"}</b>{" "}
        </Typography>
      </Box>
      {!editingEnabled && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6">Produkty:</Typography>

          {record?.products?.map((product: any) => (
            <Box sx={{ backgroundColor: "#121212", my: 1, py: 1 }}>
              <Typography variant="h6" sx={{ ml: 2 }}>
                {`${product.count}x  ${product.title}`}
                <Typography
                  variant="caption"
                  sx={{ ml: 2 }}
                >{`(${product.price}€ /ks/deň)`}</Typography>
              </Typography>
            </Box>
          ))}
        </Box>
      )}
      {editingEnabled && (
        <Box>
          <Box>
            <TextField
              select
              label="Produkty"
              onChange={handleProductSelect}
              value=""
              defaultValue=""
              sx={{ minWidth: "200px" }}
            >
              {products.options.map((product: any) => (
                <MenuItem key={product.id} value={product}>
                  {product.title.sk +
                    " (" +
                    product?.availableCount +
                    "/" +
                    product?.count +
                    ")"}
                  <Typography
                    variant="caption"
                    sx={{ ml: 2 }}
                  >{`(${product.price}€ /ks/deň)`}</Typography>
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box>
            {products.selected.map((product: any) => (
              <Box
                key={product.id}
                sx={{
                  m: 1,
                  display: "flex",
                  alignItems: "center",
                  borderBottom: "1px solid grey",
                  backgroundColor:
                    product.desiredCount > product.availableCount
                      ? "#7e2a2a"
                      : "#121212",
                }}
              >
                <TextField
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleCountChange(event.target.value, product);
                  }}
                  value={
                    typeof product.desiredCount === "string"
                      ? product.desiredCount
                      : "1"
                  }
                  variant="outlined"
                  type="number"
                  inputProps={{ min: 1, max: product.availableCount }}
                  size="small"
                  sx={{ mr: 2 }}
                />
                <Typography variant="h6">
                  {`(dostupné ${product?.availableCount}/${product?.count}) ${product.title.sk}`}
                  <Typography
                    variant="caption"
                    sx={{ ml: 2 }}
                  >{`(${product.price}€ /ks/deň)`}</Typography>
                </Typography>
                <Box sx={{ ml: "auto", mr: 2 }}>
                  <IconButton onClick={() => handleProductDeselect(product)}>
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      )}
      <Box sx={{ mt: 4 }}>
        <Typography>
          {"Vypočítaná cena: "}
          <b>
            {editingEnabled
              ? calculatePrice(products.selected, startDate, endDate).toFixed(
                  2
                ) + " €"
              : record?.calculatedPrice?.toFixed(2) + " €"}
          </b>
        </Typography>
        <TextField
          label="Nastavená cena"
          value={price}
          onChange={handlePriceChange}
          sx={{ display: "block" }}
          disabled={record.state === "CANCELED"}
        />
      </Box>
      <Box sx={{ mt: 4 }}>
        <TextField
          select
          label="Stav objednávky"
          value={orderState}
          onChange={handleOrderStateChange}
          variant="outlined"
          sx={{ width: ["100%", "50%", "30%"] }}
          disabled={record.state === "CANCELED"}
        >
          {stateOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <Box sx={{ mt: 4 }}>
        <TextField
          label="Poznámka"
          multiline
          minRows={2}
          maxRows={5}
          inputProps={{ maxLength: 2000 }}
          value={managerNote}
          onChange={handleManagerNoteChange}
          variant="outlined"
          fullWidth
          disabled={record.state === "CANCELED"}
        />
      </Box>
      <Button
        variant="contained"
        color="success"
        onClick={handleSaveButton}
        sx={{ mt: 3 }}
        disabled={
          awaitingResponse ||
          record.state === "CANCELED" ||
          (editingEnabled && products.selected.length === 0)
        }
      >
        Uložiť
      </Button>
    </Box>
  );
}

export default function OrderEdit(props: any) {
  return (
    <Edit {...props} mutationMode="pessimistic" title={<OrderTitle />}>
      <OrderEditFields />
    </Edit>
  );
}
