import { Datagrid, List, Filter, TextField, TextInput, EditButton } from "react-admin";
import ListPagination from "../../components/ListPagination";

const PortfolioFilter = (props: any) => {
        return (
                <Filter {...props}>
                        <TextInput label="Search" source="title" alwaysOn />
                </Filter>
        );
};

export default function PortfolioList(props: any) {
        return (
                <List {...props} filters={<PortfolioFilter />} pagination={<ListPagination />}>
                        <Datagrid bulkActionButtons={false} rowClick="edit">
                                <TextField source="title.sk" label="Názov" sortable={false} />
                                <EditButton label="" />
                        </Datagrid>
                </List>
        );
}
