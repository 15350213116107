import { SimpleForm, Create } from "react-admin";
import ProductFields from "./ProductFields";

export default function ProductCreate (props: any) {
    return (
        <Create {...props} redirect="list">
            <SimpleForm>
                <ProductFields edit={false}/>
            </SimpleForm>
        </Create>
    )
}
