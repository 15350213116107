import { Edit, SimpleForm, TextInput, TextField } from "react-admin";
import UserRoleField from "../../components/UserRoleField";

export default function UserEdit (props: any) {
    return (
        <Edit {...props} mutationMode="pessimistic">
            <SimpleForm>
                <TextField source="id" />
                <TextInput source="email" disabled />
                <UserRoleField source="role" label="Rola používateľa" />
            </SimpleForm>
        </Edit>
    )
}
