import { RichTextInput } from "ra-input-rich-text";
import { TranslatableInputs } from "react-admin";
import { TextInput, ImageInput, ImageField } from "react-admin";
import { LOCALES } from "../../utils/constants";
import Box from "@mui/material/Box";
import SlugInput from "../../components/SlugInput";
import PortfolioCategoryInput from "../../components/PortfolioCategoryInput";
import YoutubePreview from "../../components/YoutubePreview";
import { ArrayInput } from "react-admin";
import { SimpleFormIterator } from "react-admin";
import { required } from "react-admin";

const imageInputStyles = {
  "& .RaFileInput-dropZone": { border: 1, borderColor: "#c0c0c1" },
};

export default function PortfolioFields() {
  return (
    <>
      <Box sx={{ mb: 1 }}>
        <TranslatableInputs locales={LOCALES} defaultLocale="sk">
          <TextInput
            source="title"
            label="Názov"
            helperText={false}
            fullWidth
            defaultValue={""}
          />
          <SlugInput
            source="slug"
            label="Slug, ktorý bude v URL."
            fullWidth
            defaultValue={""}
            disabled
          />
          <TextInput
            source="subtitle"
            label="Podnázov"
            helperText={false}
            fullWidth
            defaultValue={""}
          />
          <RichTextInput
            source="cast"
            label="CAST"
            helperText={false}
            fullWidth
            defaultValue={""}
          />
          <RichTextInput
            source="description"
            label="Podrobný popis"
            helperText={false}
            fullWidth
            defaultValue={""}
          />
          <TextInput
            source="slogan"
            label="Slogan"
            helperText={false}
            fullWidth
            defaultValue={""}
            multiline
            minRows={2}
          />
        </TranslatableInputs>
      </Box>
      <PortfolioCategoryInput
        source="categories"
        label="Kategórie"
        helperText={false}
        fullWidth
        variant="outlined"
        validate={required()}
      />
      <ImageInput
        source="mainImage"
        label="Titulný obrázok"
        labelSingle="Semka veľky obrazok"
        accept="image/*"
        defaultValue={null}
        sx={imageInputStyles}
      >
        <ImageField source="src" title="fileName" />
      </ImageInput>
      <ImageInput
        source="otherImages"
        label="Ostatné obrázky"
        labelMultiple="Fotky z akcie"
        accept="image/*"
        multiple
        defaultValue={null}
        sx={imageInputStyles}
      >
        <ImageField source="src" title="fileName" />
      </ImageInput>
      <ArrayInput source="youtubeIds">
        <SimpleFormIterator inline index>
          <TextInput
            source="id"
            label="Youtube ID"
            helperText={false}
            fullWidth
            defaultValue={""}
          />
          <YoutubePreview />
        </SimpleFormIterator>
      </ArrayInput>
      <ImageInput source="thumbnailImage" label="Thumbnail" labelSingle="Thumbnail" accept="image/*" defaultValue={null} sx={imageInputStyles}>
        <ImageField source="src" title="fileName" />
      </ImageInput>
    </>
  );
}
