import { ChangeEvent, useState } from "react";
import { auth, functions } from '../firebase'
import { httpsCallable } from 'firebase/functions'

import { usePermissions } from 'react-admin';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useRecordContext } from "react-admin";
import { useNotify } from "react-admin";

interface Props {
    source: any
    label?: string
}



export default function R2FileField ({ source, label }: Props) {
    const { permissions } = usePermissions()
    const [loading, setLoading] = useState(false)
    const record = useRecordContext()
    const [file, setFile] = useState<string>(record['thumbnailVideo'] || "");
    const [newFile, setNewFile] = useState<File>();
    const notify = useNotify();


    const uploadFile = async (event: any) => {
        if (!newFile) {
            return
        }

        setLoading(true)

        const token = await auth.currentUser?.getIdToken()

        const formData = new FormData()

        formData.append('file', newFile)
        formData.append('portfolioId', record['id'])

        const response = await fetch('https://us-central1-grade-production-admin.cloudfunctions.net/uploadFile', {
            method: 'POST',
            body: formData,
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }).catch(() => {
            setLoading(false)
            notify('Error', { type: 'error' });
        })

        const data = await response?.json()

        if (data.success) {
            notify('Súbor nahraný', { type: 'success' });
        } else {
            notify(data.message || 'Error', { type: 'error' });
        }

        setFile(data.fileName)
        setLoading(false)
    };

    const deleteFile = async () => {
        setLoading(true)
        const funRef = httpsCallable(functions, 'deleteFile')
        await funRef({ portfolioId: record['id'], fileName: file })
            .then(() => {
                setFile("");
                notify('Súbor bol zmazaný', { type: 'success' });
            })
            .catch(() => {})
        setLoading(false)
    };


    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
          setNewFile(e.target.files[0]);
        }
    };

    if (permissions?.role !== 'admin') {
        return null
    }

    return (
        <div>
            <Typography variant="caption" component="h2" color="textSecondary">{label}</Typography>
            <Typography variant="caption" component="h2" color="textSecondary">{file}</Typography>
            {
                !file &&
                <>
                    <input type="file" onChange={handleFileChange} accept="video/mp4"/>
                    <small>Max file size 20MB </small>
                    <Button
                        disabled={loading}
                        onClick={uploadFile}
                        color="primary"
                        startIcon={loading && <CircularProgress />}
                    >Nahrať MP4</Button>
                </>
            }
            {   file &&
                <>
                    <video controls autoPlay muted width="250">
                        <source src={`https://static.gradeproduction.sk/u/${file}`} type="video/mp4"/>
                    </video>
                    <Button
                        color="error"
                        disabled={loading}
                        onClick={deleteFile}
                        startIcon={loading && <CircularProgress />}
                    >Vymazať</Button>
                </>
            }
        </div>
    )
};
