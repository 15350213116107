import { useTranslatableContext } from "react-admin";
import TextField from "@mui/material/TextField";
import { useWatch } from 'react-hook-form';
import slugify from "slugify";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useInput } from "react-admin";
import { useRecordContext } from "react-admin";

export default function SlugInput(props: any){

    const {selectedLocale} = useTranslatableContext();
    let title = useWatch({name: "title"});
    const { setValue, getFieldState } = useFormContext();
    const record = useRecordContext();

    useEffect(() => {
        if (typeof title?.[selectedLocale] === "string"){
            if (getFieldState("title." + selectedLocale).isDirty){
                title[selectedLocale] = slugify(title?.[selectedLocale], {locale: selectedLocale, lower: true});
                setValue("slug", title, {shouldTouch: true, shouldDirty: true});
            }
            else {
                if (title?.[selectedLocale]?.length !== 0) {
                    title[selectedLocale] = record?.slug?.[selectedLocale];
                    setValue("slug", title, {shouldTouch: false, shouldDirty: false});
                }
                else {
                    title[selectedLocale] = "";
                    setValue("slug", title, {shouldTouch: false, shouldDirty: false});
                }
            }
        }
    }, [title, selectedLocale, record, setValue, getFieldState]);

    const { field } = useInput(props);

    return (
        <TextField
            {...field}
            label={props?.label}
            fullWidth={props?.fullWidth}
            disabled={props?.disabled}
        />
    );
}