import { Toolbar } from "react-admin";
import { DeleteButton } from "react-admin";
import { SaveButton } from "react-admin";
import { Edit, SimpleForm } from "react-admin";
import PortfolioFields from "./PortfolioFields";
import { useRecordContext } from "react-admin";
import R2FileField from "../../components/R2FileField";

const PortfolioTitle = () => {
    const record = useRecordContext();
    return <span>Portfólio: <b>{record ? `${record.title.sk}` : ''}</b></span>;
}

export default function PortfolioEdit (props: any) {

    return (
        <Edit {...props} mutationMode="pessimistic" title={<PortfolioTitle/>}>
            <SimpleForm toolbar={
                <Toolbar>
                    <SaveButton /><DeleteButton/>
                </Toolbar>
            }>
                <PortfolioFields/>
                <R2FileField source="videoThumbnail" label="Video Thumbnail"/>
            </SimpleForm>
        </Edit>
    )
}
