import { Toolbar } from "react-admin";
import { DeleteButton } from "react-admin";
import { SaveButton } from "react-admin";
import { Edit, SimpleForm } from "react-admin";
import ProductFields from "./ProductFields";
import { useRecordContext } from "react-admin";

const ProductTitle = () => {
  const record = useRecordContext();
  return (
    <span>
      Produkt: <b>{record ? `${record.title.sk}` : ""}</b>
    </span>
  );
};

export default function ProductEdit(props: any) {
  return (
    <Edit {...props} mutationMode="pessimistic" title={<ProductTitle />}>
      <SimpleForm
        toolbar={
          <Toolbar>
            <SaveButton />
            {/* <DeleteButton/> */}
          </Toolbar>
        }
      >
        <ProductFields edit={true} />
      </SimpleForm>
    </Edit>
  );
}
