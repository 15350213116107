import { RichTextInput } from "ra-input-rich-text";
import { TranslatableInputs } from "react-admin";
import { TextInput, ImageInput, ImageField } from "react-admin";
import { LOCALES } from "../../utils/constants";
import Box from "@mui/material/Box";
import SlugInput from "../../components/SlugInput";
import CategoryInput from "../../components/CategoryInput";
import { SelectInput } from "react-admin";
import { NumberInput } from "react-admin";
import { required } from "react-admin";
import { DateTimeInput } from "react-admin";

const imageInputStyles = {
  "& .RaFileInput-dropZone": { border: 1, borderColor: "#c0c0c1" },
};

const stateChoices = [
  { id: "public", name: "Zverejnený" },
  { id: "draft", name: "Koncept" },
  { id: "archived", name: "Archivovaný" },
];

export default function ProductFields({ edit }: { edit: boolean }) {
  return (
    <>
      <Box sx={{ mb: 1 }}>
        <TranslatableInputs locales={LOCALES} defaultLocale="sk">
          <TextInput
            source="title"
            label="Názov produktu"
            helperText={false}
            fullWidth
            defaultValue={""}
          />
          {edit ? (
            <TextInput
              source="slug"
              label="Slug, ktorý bude v URL."
              helperText={false}
              fullWidth
              defaultValue={""}
            />
          ) : (
            <SlugInput
              source="slug"
              label="Slug, ktorý bude v URL."
              fullWidth
              defaultValue={""}
              disabled
            />
          )}
          <TextInput
            source="subtitle"
            label="Podnázov"
            helperText={false}
            fullWidth
            defaultValue={""}
          />
          <RichTextInput
            source="description"
            label="Podrobný opis"
            helperText={false}
            fullWidth
            defaultValue={""}
          />
        </TranslatableInputs>
      </Box>
      {/* <TextInput source="brand" label="Značka" helperText={false} fullWidth defaultValue={""}/> */}
      <CategoryInput
        source="categories"
        label="Kategórie"
        helperText={false}
        fullWidth
        variant="outlined"
      />
      <NumberInput source="count" label="Počet" validate={required()} />
      <SelectInput
        source="state"
        choices={stateChoices}
        defaultValue="public"
        label="Stav"
      />
      <NumberInput
        source="price"
        label="Cena"
        defaultValue={0}
        validate={required()}
      />
      <NumberInput source="salePrice" label="Akciová cena" defaultValue={0} />
      {edit && <DateTimeInput source="createdAt" label="Dátum vytvorenia" />}
      <ImageInput
        source="mainImage"
        label="Hlavný obrázok"
        labelSingle="Tuto daj hlavný obraštek, ten veľký hej?"
        accept="image/*"
        defaultValue={null}
        sx={imageInputStyles}
      >
        <ImageField source="src" title="fileName" />
      </ImageInput>
      <ImageInput
        source="otherImages"
        label="Ostatné obrázky"
        labelMultiple="Sem nasekaj tote ostatne fotečky čo tam chceš mať"
        accept="image/*"
        multiple
        defaultValue={null}
        sx={imageInputStyles}
      >
        <ImageField source="src" title="fileName" />
      </ImageInput>
    </>
  );
}
