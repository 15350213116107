import { Resource } from "react-admin";

import { Admin } from "react-admin";
import {
  FirebaseDataProvider,
  FirebaseAuthProvider,
  RAFirebaseOptions,
} from "react-admin-firebase";
import LoginPage from "./pages/LoginPage";
import ProductCreate from "./resources/product/ProductCreate";
import ProductEdit from "./resources/product/ProductEdit";
import ProductList from "./resources/product/ProductList";
import ProductShow from "./resources/product/ProductShow";
import UserList from "./resources/user/UserList";

import UserIcon from "@mui/icons-material/People";
import UserEdit from "./resources/user/UserEdit";

import { app, config } from "./firebase";
import CategoryList from "./resources/categories/CategoryList";
import CategoryCreate from "./resources/categories/CategoryCreate";
import CategoryEdit from "./resources/categories/CategoryEdit";
import { defaultTheme } from "react-admin";
import PortfolioList from "./resources/portfolio/PortfolioList";
import PortfolioCreate from "./resources/portfolio/PortfolioCreate";
import PortfolioEdit from "./resources/portfolio/PortfolioEdit";
import OrderList from "./resources/orders/OrderList";
import OrderCreate from "./resources/orders/OrderCreate";
import OrderEdit from "./resources/orders/OrderEdit";

const theme = {
  ...defaultTheme,
  palette: {
    mode: "dark", // Switching the dark mode on is a single property value change.
  },
};

const options: RAFirebaseOptions = {
  // logging: process.env.NODE_ENV === "development",
  app,
  // watch: ["products"],
  renameMetaFields: {
    created_at: "createdAt", // default: 'createdate'
    created_by: "createdBy", // default: 'createdby'
    updated_at: "updatedAt", // default: 'lastupdate'
    updated_by: "updatedBy", // default: 'updatedby'
  },
  lazyLoading: {
    enabled: true,
  },
  logging: false,
  // firestoreCostsLogger: {
  //     enabled: true,
  // },
  useFileNamesInStorage: true,
  associateUsersById: true,
};

const dataProvider = FirebaseDataProvider(config, options);
const authProvider = FirebaseAuthProvider(config, options);

export default function App() {
  return (
    <Admin
      theme={theme}
      dataProvider={dataProvider}
      authProvider={authProvider}
      loginPage={LoginPage}
    >
      {() => [
        <Resource
          name="orders"
          options={{ label: "Objednávky" }}
          list={OrderList}
          create={OrderCreate}
          edit={OrderEdit}
        />,
        <Resource
          name="products"
          options={{ label: "Produkty" }}
          list={ProductList}
          show={ProductShow}
          create={ProductCreate}
          edit={ProductEdit}
        />,
        <Resource
          name="categories"
          options={{ label: "Kategórie" }}
          list={CategoryList}
          create={CategoryCreate}
          edit={CategoryEdit}
        />,
        <Resource
          name="portfolio"
          options={{ label: "Portfólio" }}
          list={PortfolioList}
          create={PortfolioCreate}
          edit={PortfolioEdit}
        />,
        <Resource
          name="users"
          options={{ label: "Užívatelia" }}
          icon={UserIcon}
          list={UserList}
          edit={UserEdit}
        />,
      ]}
    </Admin>
  );
}
