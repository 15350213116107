import { useWatch } from 'react-hook-form';
import YouTube from 'react-youtube';
import { useSimpleFormIteratorItem } from "react-admin";

export default function YoutubePreview(props: any){

    const item = useSimpleFormIteratorItem()
    let youtubeId = useWatch({name: `youtubeIds.${item.index}.id`});

    return (
        youtubeId && <YouTube videoId={youtubeId} />
    );
}
