import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { Create } from "react-admin";
import {
  TextField,
  Box,
  Typography,
  MenuItem,
  IconButton,
  Button,
} from "@mui/material";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useGetList } from "react-admin";
import CloseIcon from "@mui/icons-material/Close";
import { auth, functions } from "../../firebase";
import { httpsCallable } from "firebase/functions";
import { useNotify } from "react-admin";
import { useRedirect } from "react-admin";
import { useRefresh } from "react-admin";
import { OUTSIDE_WORK_HOURS_PRICE } from "../../utils/constants";

function filterProductAvailability(
  products: any,
  startDate: DateTime,
  endDate: DateTime
) {
  products = structuredClone(products);
  products.forEach((product: any) => {
    let maxRented = 0;
    let idxDate = startDate;
    let safeguard = 0;
    do {
      const rentedCount =
        product?.rentedAt?.[`${idxDate.year}`]?.[`${idxDate.month}`]?.[
          `${idxDate.day}`
        ] || 0;
      maxRented = Math.max(maxRented, rentedCount);
      safeguard++;
      idxDate = idxDate.plus({ days: 1 });
    } while (
      idxDate.startOf("day") <= endDate.startOf("day") &&
      safeguard < 10000
    );
    if (safeguard >= 10000) {
      console.log("coska nedobre.");
    }
    product.availableCount = Math.max(product.count - maxRented, 0);
  });

  return products;
}

function sortProducts(products: any) {
  products.sort((a: any, b: any) => {
    const titleA = a?.title?.sk?.toUpperCase();
    const titleB = b?.title?.sk?.toUpperCase();
    if (titleA < titleB) {
      return -1;
    }
    if (titleA > titleB) {
      return 1;
    }
    return 0;
  });
  return products;
}

function calculateIsOutsideWorkHours(startDate: DateTime, endDate: DateTime) {
  return (
    startDate.hour < 8 ||
    startDate.hour > 15 ||
    endDate.hour < 8 ||
    endDate.hour > 15 ||
    startDate.weekday === 6 ||
    startDate.weekday === 7 ||
    endDate.weekday === 6 ||
    endDate.weekday === 7
  );
}

function calculatePrice(products: any, startDate: DateTime, endDate: DateTime) {
  const isOutsideWorkHours = calculateIsOutsideWorkHours(startDate, endDate);
  let idxDate = startDate;
  const rentDays: DateTime[] = [];
  let safeguard = 0;
  let totalPrice = 0;
  do {
    rentDays.push(idxDate);
    safeguard++;
    idxDate = idxDate.plus({ days: 1 });
  } while (
    idxDate.startOf("day") <= endDate.startOf("day") &&
    safeguard < 1000
  );
  if (safeguard >= 10000) {
    console.log("coska nedobre.");
  }
  products.forEach((product: any) => {
    totalPrice +=
      (product.salePrice ? product.salePrice : product.price) *
      (parseInt(product.desiredCount, 10) || 0) *
      rentDays.length;
  });
  totalPrice += isOutsideWorkHours ? OUTSIDE_WORK_HOURS_PRICE : 0;
  return totalPrice;
}

export default function OrderCreate(props: any) {
  const { data: productsData } = useGetList("products", {
    pagination: { page: 1, perPage: 200 },
    sort: { field: "title.sk", order: "DESC" },
  });
  const [products, setProducts] = useState<any>({ options: [], selected: [] });
  const [startDate, setStartDate] = useState<DateTime>(DateTime.now());
  const [endDate, setEndDate] = useState<DateTime>(
    DateTime.now().plus({ minutes: 1 })
  );
  const isOutsideWorkHours = calculateIsOutsideWorkHours(startDate, endDate);
  const [price, setPrice] = useState<string>(
    isOutsideWorkHours ? "" + OUTSIDE_WORK_HOURS_PRICE : "0"
  );
  // such naming because of backend order schema but will be saved as manager note
  const [customerNote, setCustomerNote] = useState<string>("");
  const [awaitingResponse, setAwaitingResponse] = useState<boolean>(false);
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  useEffect(() => {
    if (
      productsData &&
      products?.options?.length === 0 &&
      products?.selected?.length === 0
    ) {
      const filteredProducts = filterProductAvailability(
        productsData,
        startDate,
        endDate
      );
      setProducts((oldProducts: any) => {
        const newProducts: any = {};
        newProducts.options = sortProducts(filteredProducts);
        newProducts.selected = [];
        return newProducts;
      });
    }
  }, [productsData, products, startDate, endDate]);

  const handleProductSelect = (event: any) => {
    const selectedValue = structuredClone(event.target.value);
    selectedValue.desiredCount = "1";
    setProducts((oldProducts: any) => {
      const newProducts = structuredClone(oldProducts);
      newProducts.options = newProducts.options.filter(
        (product: any) => product.id !== selectedValue.id
      );
      newProducts.selected.push(selectedValue);
      sortProducts(newProducts.options);
      sortProducts(newProducts.selected);
      setPrice(
        "" + calculatePrice(newProducts.selected, startDate, endDate).toFixed(2)
      );
      return newProducts;
    });
  };

  const handleStartDateChange = (newValue: DateTime | null) => {
    if (newValue === null) {
      setStartDate(DateTime.now());
    } else if (newValue >= endDate) {
      setEndDate(newValue.plus({ minutes: 1 }));
      setStartDate(newValue);
      setPrice(
        "" + calculatePrice(products.selected, newValue, newValue).toFixed(2)
      );
      setProducts((oldProducts: any) => {
        const newProducts: any = {};
        newProducts.options = filterProductAvailability(
          oldProducts.options,
          newValue,
          newValue
        );
        newProducts.selected = filterProductAvailability(
          oldProducts.selected,
          newValue,
          newValue
        );
        return newProducts;
      });
    } else {
      setStartDate(newValue);
      setPrice(
        "" + calculatePrice(products.selected, newValue, endDate).toFixed(2)
      );
      setProducts((oldProducts: any) => {
        const newProducts: any = {};
        newProducts.options = filterProductAvailability(
          oldProducts.options,
          newValue,
          endDate
        );
        newProducts.selected = filterProductAvailability(
          oldProducts.selected,
          newValue,
          endDate
        );
        return newProducts;
      });
    }
  };

  const handleEndDateChange = (newValue: DateTime | null) => {
    if (newValue === null) {
      setEndDate(DateTime.now());
    } else if (newValue < startDate) {
      setStartDate(newValue.minus({ minutes: 1 }));
      setEndDate(newValue);
      setProducts((oldProducts: any) => {
        const newProducts: any = {};
        newProducts.options = filterProductAvailability(
          oldProducts.options,
          newValue.minus({ minutes: 1 }),
          newValue
        );
        newProducts.selected = filterProductAvailability(
          oldProducts.selected,
          newValue.minus({ minutes: 1 }),
          newValue
        );
        return newProducts;
      });
    } else {
      setEndDate(newValue);
      setPrice(
        "" + calculatePrice(products.selected, startDate, newValue).toFixed(2)
      );
      setProducts((oldProducts: any) => {
        const newProducts: any = {};
        newProducts.options = filterProductAvailability(
          oldProducts.options,
          startDate,
          newValue
        );
        newProducts.selected = filterProductAvailability(
          oldProducts.selected,
          startDate,
          newValue
        );
        return newProducts;
      });
    }
  };

  const handleCountChange = (value: string, product: any) => {
    let count: string | number = parseInt(value, 10);
    if (count > product.availableCount) {
      count = product.availableCount;
    } else if (count < 1) {
      count = 1;
    } else {
      count = value;
    }
    setProducts((oldProducts: any) => {
      const newProducts = structuredClone(oldProducts);
      const newProduct = newProducts.selected.find(
        (prod: any) => prod.id === product.id
      );
      newProduct.desiredCount = "" + count;
      setPrice(
        "" + calculatePrice(newProducts.selected, startDate, endDate).toFixed(2)
      );
      return newProducts;
    });
  };

  const handleProductDeselect = (product: any) => {
    const deselectedProduct = structuredClone(product);
    deselectedProduct.desiredCount = 0;
    setProducts((oldProducts: any) => {
      const newProducts = structuredClone(oldProducts);
      newProducts.selected = newProducts.selected.filter(
        (product: any) => product.id !== deselectedProduct.id
      );
      newProducts.options.push(deselectedProduct);
      sortProducts(newProducts.options);
      sortProducts(newProducts.selected);
      setPrice(
        "" + calculatePrice(newProducts.selected, startDate, endDate).toFixed(2)
      );
      return newProducts;
    });
  };

  const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrice(event.target.value.replace(",", "."));
  };

  const handleCustomerNoteChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCustomerNote(event.target.value);
  };

  const handleSaveButton = () => {
    setAwaitingResponse(true);
    console.log("Making order...");

    if (!auth.currentUser || products.selected.length === 0) {
      setAwaitingResponse(false);
      notify(`Vyber nejaký produkt`, { type: "warning" });
      return;
    }

    const findDesiredCountError = products.selected.some(
      (product: any) => product.desiredCount > product.availableCount
    );
    if (findDesiredCountError) {
      setAwaitingResponse(false);
      notify(`Niektorý z produktov nie je dostupný v požadovanom množstve`, {
        type: "warning",
      });
      return;
    }

    const transformedProducts = products.selected.map((product: any) => {
      return {
        id: product?.id,
        count: parseInt(product?.desiredCount),
        title: product?.title?.sk,
      };
    });

    const order = {
      products: transformedProducts,
      rentAt: startDate.toISO(),
      returnAt: endDate.toISO(),
      price: parseFloat(price),
      user: {
        onCompany: false,
        firstName: "admin",
        lastName: "admin",
        email: auth.currentUser.email,
        phone: "0000",
        country: "Slovensko",
        city: "Sliač",
        zip: "96231",
        street: "Jarná 13",
      },
      customerNote: customerNote,
    };

    console.log(order);
    const makeOrder = httpsCallable(functions, "makeOrder");
    makeOrder(order)
      .then((result: any) => {
        const data = result.data;
        console.log(data);
        setAwaitingResponse(false);
        notify(result?.data?.message, {
          type: result?.data?.success ? "success" : "error",
        });
        redirect("list", "orders");
      })
      .catch((e) => {
        notify("Dajaka chyba, neznam.", { type: "error" });
        setAwaitingResponse(false);
        refresh();
      });
  };

  if (!productsData || !products?.selected || !products?.options) {
    return <>načítavam produkty ta počkaj dobre?</>;
  }

  //console.log(products);

  return (
    <Create {...props}>
      <Box sx={{ p: 2 }}>
        <DateTimePicker
          label="Začiatok"
          onChange={handleStartDateChange}
          value={startDate}
          renderInput={(params: any) => (
            <TextField
              onKeyDown={(e) => {
                e.preventDefault();
              }}
              {...params}
            />
          )}
          inputFormat="dd/MM/yyyy HH:mm"
          ampm={false}
        />
        <DateTimePicker
          label="Koniec"
          onChange={handleEndDateChange}
          value={endDate}
          renderInput={(params: any) => (
            <TextField
              onKeyDown={(e) => {
                e.preventDefault();
              }}
              {...params}
            />
          )}
          inputFormat="dd/MM/yyyy HH:mm"
          ampm={false}
        />
        <Typography>
          {" "}
          Mimo pracovný čas?: <b>{isOutsideWorkHours ? "Áno" : "Nie"}</b>{" "}
        </Typography>
        <Box>
          <TextField
            select
            label="Produkty"
            onChange={handleProductSelect}
            value=""
            defaultValue=""
            sx={{ minWidth: "200px" }}
          >
            {products.options.map((product: any) => (
              <MenuItem key={product.id} value={product}>
                {product.title.sk +
                  " (" +
                  product?.availableCount +
                  "/" +
                  product?.count +
                  ")"}
                <Typography
                  variant="caption"
                  sx={{ ml: 2 }}
                >{`(${product.price}€ /ks/deň)`}</Typography>
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Box>
          {products.selected.map((product: any) => (
            <Box
              key={product.id}
              sx={{
                m: 1,
                display: "flex",
                alignItems: "center",
                borderBottom: "1px solid grey",
                backgroundColor:
                  product.desiredCount > product.availableCount
                    ? "#7e2a2a"
                    : "#121212",
              }}
            >
              <TextField
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleCountChange(event.target.value, product);
                }}
                value={
                  typeof product.desiredCount === "string"
                    ? product.desiredCount
                    : "1"
                }
                variant="outlined"
                type="number"
                inputProps={{ min: 1, max: product.availableCount }}
                size="small"
                sx={{ mr: 2 }}
              />
              <Typography variant="h6">
                {"(dostupné " +
                  product?.availableCount +
                  "/" +
                  product?.count +
                  ") " +
                  product.title.sk}
                <Typography
                  variant="caption"
                  sx={{ ml: 2 }}
                >{`(${product.price}€ /ks/deň)`}</Typography>
              </Typography>
              <Box sx={{ ml: "auto", mr: 2 }}>
                <IconButton onClick={() => handleProductDeselect(product)}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          ))}
        </Box>
        <Box sx={{ mt: 4 }}>
          <Typography>
            {"Vypočítaná cena: "}
            <b>
              {calculatePrice(products.selected, startDate, endDate).toFixed(
                2
              ) + " €"}
            </b>
          </Typography>
          <TextField
            label="Nastavená cena"
            value={price}
            onChange={handlePriceChange}
            sx={{ display: "block" }}
          />
        </Box>
        <TextField
          label="Poznámka"
          multiline
          minRows={2}
          maxRows={5}
          inputProps={{ maxLength: 2000 }}
          value={customerNote}
          onChange={handleCustomerNoteChange}
          variant="outlined"
          fullWidth
          sx={{ mt: 2 }}
        />
        <Button
          variant="contained"
          color="success"
          onClick={handleSaveButton}
          sx={{ mt: 3 }}
          disabled={awaitingResponse}
        >
          Uložiť
        </Button>
      </Box>
    </Create>
  );
}
