import { SimpleForm, Create } from "react-admin";
import PortfolioFields from "./PortfolioFields";
// import { Button } from "@mui/material";
// import { httpsCallable } from "firebase/functions";
// import { functions } from "../../firebase";

export default function PortfolioCreate(props: any) {
  // const handleTestButton = () => {
  //   const test = httpsCallable(functions, "test");
  //   test().then((result: any) => {
  //     console.log("heelo");
  //   });
  // };

  return (
    <Create {...props} redirect="list">
      <SimpleForm>
        {/* <Button onClick={handleTestButton}>Tesst</Button> */}
        <PortfolioFields />
      </SimpleForm>
    </Create>
  );
}
