import { Datagrid, List, Filter, TextField, TextInput, EditButton } from "react-admin";
import ListPagination from "../../components/ListPagination";

const UserFilter = (props: any) => {
        return (
                <Filter {...props}>
                        <TextInput label="Search" source="email" alwaysOn />
                </Filter>
        );
};

export default function UserList(props: any) {
        return (
                <List {...props} filters={<UserFilter />} pagination={<ListPagination />}>
                        <Datagrid>
                                <TextField source="email" sortable={false} />
                                <TextField source="role" sortable={false} />
                                <EditButton label="" />
                        </Datagrid>
                </List>
        );
}
