import { SelectArrayInput } from "react-admin";
import { useGetOne } from "react-admin";

export default function CategoryInput(props: any){

    const { data } = useGetOne(
        "categories",
        { id: "categories"}
    )
    const choices: any[] = [];
    const createCategoryList = (parentId: string, previousName: string) => {
        if (data && data[parentId]){
            const name = previousName + "->" + data[parentId]?.title.sk;
            const item = {id: parentId, name: name}
            choices.push(item);
            if (data[parentId]?.children){
                data[parentId]?.children.forEach((childId: string) => {
                    createCategoryList(childId, name);
                })
            }
        }
    }

    createCategoryList("root", "");

    return (
        <SelectArrayInput {...props} choices={choices} defaultValue={null} />
    );
}