import { SelectArrayInput } from "react-admin";
import { useGetOne } from "react-admin";

export default function PortfolioCategoryInput(props: any){

    const { data } = useGetOne(
        "portfolioCategories",
        { id: "categories"}
    )
    const choices: any[] = [];

    if (!!data?.categories){
        Object.keys(data.categories).forEach((id) => {
            choices.push({id: id, name: data.categories[id]?.title?.sk})
        })
    }

    return (
        <SelectArrayInput {...props} choices={choices} />
    );
}
