import { useEffect, useState } from "react";
import { functions } from '../firebase'
import { httpsCallable } from 'firebase/functions'

import { usePermissions } from 'react-admin';

// import { makeStyles } from '@mui/styles';
// import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
import { useRecordContext } from "react-admin";

interface Props {
    source: any
    label?: string
}



export default function UserRoleField ({ source, label }: Props) {
    // const theme = useTheme();
    // const useStyles = makeStyles(() => ({
    //     container: {
    //         display: 'flex',
    //         flexWrap: 'wrap',
    //     },
    //     formControl: {
    //         margin: theme.spacing(1),
    //         minWidth: 220,
    //     },
    // }));
    // const classes = useStyles();
    const { permissions } = usePermissions()
    const [open, setOpen] = useState(false);
    const [role, setRole] = useState('');
    const [loading, setLoading] = useState(false);
    const record = useRecordContext()

    useEffect(() => {
        setRole(record['role'] || 'user')
    },[ record ])

    const handleChange = async (event: any) => {
        const value = event.target.value
        setLoading(true)
        const funRef = httpsCallable(functions, 'changeUserRole')
        await funRef({ userId: record['id'], role: value })
            .then(() => {
                setRole(value);
            })
            .catch(() => {})
        setLoading(false)
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div>
            <Typography variant="caption" component="h2" color="textSecondary">{label}</Typography>
            <Typography component="span" variant="body2">{role} </Typography>
            {
                permissions?.role === 'admin' && <Button onClick={handleClickOpen}>Zmeniť rolu</Button>
            }
            <Dialog
                disableEscapeKeyDown
                open={open}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        handleClose();
                    }
                }}
            >
                <DialogContent>
                    <form className="">
                        <FormControl className="">
                            <InputLabel htmlFor="change-role">Zmeň rolu používateľa</InputLabel>
                            {   !loading
                                ?   <Select
                                        native
                                        value={role}
                                        onChange={handleChange}
                                        input={<Input id="change-role" />}
                                    >
                                        <option value="admin">admin</option>
                                        <option value="user">user</option>
                                    </Select>
                                :   <CircularProgress />
                            }
                        </FormControl>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Zatvoriť
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
};
